/* Footer */
.footer {
  padding: 40px 0;
  background: #000;
}
.footer p,
.footer a {
  color: #d4d4d4;
  display: flex;
  margin: 0;
  align-items: center;
}
.footer a {
  text-decoration: underline;
  margin-left: 5px;
}
.footer p img {
  margin-right: 10px;
  width: 110px;
}
.textFooter {
  padding: 0 60px;
  height: 500px;
  display: flex;
  align-items: center;
}
.textFooter h1 {
  color: #fff;
}
.imgBck {
  background-image: url(../assets/footerimg.webp);
  width: 55%;
  background-position-x: left;
  background-position-y: top;
  background-repeat: no-repeat;
  height: 500px;
  margin: 0px 0px 0px 0px;
  background-size: cover;
}
.colImg {
  background: #000;
  align-items: center;
}
.disNone {
  display: none;
}
/* Media */

@media (max-width: 850px) {
  .colImg {
    flex-direction: column-reverse;
  }
  .colImg > div {
    width: 100%;
    height: 200px;
  }
  .textFooter {
    height: auto !important;
    justify-content: start;
    padding: 60px 60px;
  }
  .footer > div {
    justify-content: center;
    flex-direction: column;
  }
  .footer p {
    margin-bottom: 20px;
    margin-top: 0;
    flex-direction: column;
    font-size: 12px;
  }
  .footer p img {
    margin-right: 0;
    margin-bottom: 10px;
  }
}

/*  /Footer */
