.inputDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-bottom: 15px;
  justify-content: center;
}
.inputDiv :is(input, select) {
  display: flex;
  padding: 15px 30px;
  font-size: 15px;
  color: #000;
  background-color: #0074e81c;
  border: none;
  box-shadow: 0px 0px 0px 2px #0074e8;
  font-weight: 500;
  border-radius: 5px;
  transition: all 0.5s;
  -webkit-appearance: none;
  appearance: none;
}
.dropdown {
  position: absolute;
  right: 50px;
  width: 5px;
  transform: rotate(-90deg);
}
.inputDiv :is(input, select):focus {
  box-shadow: 0px 0px 0px 3px #0074e8;
  border: none;
  outline: none;
}
.radioDiv {
  display: flex;
}

.inputDiv input[type="radio"] {
  display: none;
  margin: 10px;
}

.inputDiv input[type="radio"] + label {
  display: inline-block;
  margin-right: 5px;
  padding: 5px 18px;
  background-color: #e7e7e7;
  border-color: #ddd;
  border-radius: 10px;
}

input[type="radio"]:checked + label {
  background-image: none;
  background-color: #0074e8;
  color: #fff;
}

.labelForm {
  font-size: 14px;
  margin-bottom: 10px !important;
}

.error,
.inputDiv .errorInput {
  margin: 5px 0 0;
  color: #0074e8;
  font-size: 14px;
  display: none;
}

.error {
  margin: 15px 0 0;
  color: #0074e8;
  font-size: 14px;
  display: flex;
  align-self: center;
}
.error svg {
  width: 18px;
  margin-right: 6px;
}

.inputDiv :is(input, select):invalid[focused="true"] ~ span {
  display: flex;
}
