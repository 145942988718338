@font-face {
  font-family: "Peugeot";
  font-weight: 300;
  src: url("./fonts/PeugeotNewLight.otf");
}

@font-face {
  font-family: "Peugeot";
  font-weight: 400;
  src: url("./fonts/PeugeotNewRegular.otf");
}

@font-face {
  font-family: "Peugeot";
  font-weight: 700;
  src: url("./fonts/PeugeotNewBold.otf");
}

body {
  background-color: #fafafa;
  position: relative;
  min-width: 375px;
  font-family: "Peugeot";
  font-weight: 400;
  font-size: 18px;
  line-height: 1.77778;
  font-weight: 300;
  overflow-x: hidden;
}

div,
input,
textarea,
select,
button,
h1,
h2,
h3,
h4,
h5,
h6,
a,
span,
a:focus {
  outline: none;
}

a:hover {
  text-decoration: none;
}

img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-zoom: none;
}
a {
  text-decoration: none;
  color: inherit;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  -o-transition: all 0.5s;
}

button {
  outline: none;
  border: none;
}

a,
button {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

h2 {
  font-size: 30px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 20px;
  color: #191919;
  line-height: 1.1;
  font-family: "Peugeot";
}

h5 {
  color: #0074e8;
  font-weight: 800;
  font-size: 15px;
  margin-bottom: 0px;
}

p {
  color: #000;
  font-size: 14px;
}

h1 {
  font-size: 54px;
  text-transform: uppercase;
  font-family: "Peugeot";
  font-weight: 300;
  line-height: 1.2;
  margin-bottom: 20px;
  color: #0074e8;
}

h1 span {
  font-weight: 700;
}

h4 {
  font-size: 27px;
}

.titleSec {
  margin-bottom: 40px;
}

strong {
  font-family: "Peugeot";
  font-weight: 900;
}

.boxShadow {
  box-shadow: 10px 20px 42px 0 rgb(55 55 55 / 23%);
}

.textCenter {
  text-align: center;
}

.relative {
  position: relative;
}

.btn {
  position: relative;
  display: inline-flex;
  text-align: center;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  font-weight: 700;
  padding: 15px 32px;
  margin: 0px;
  text-decoration: none;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  background: transparent;
  color: rgb(255 255 255);
  margin-top: 20px;
  z-index: 10;
  font-size: 13px;
  text-transform: uppercase;
  box-shadow: inset 0px 0px 0px 2px #0074e8;
  color: #0074e8;
}

.btn.whitebtn {
  background: transparent;
  color: #fff;
  box-shadow: inset 0px 0px 0px 2px #fff;
}
.btn.bluebtn {
  background: #0074e8;
  color: #fff;
  box-shadow: inset 0px 0px 0px 2px #0074e8;
}

/* Media */

@media (max-width: 850px) {
  h1 {
    font-size: 38px;
  }
  h4 {
    font-size: 20px;
  }
  h3 {
    width: 100%;
    font-size: 18px;
  }
  h2 {
    font-size: 28px;
    line-height: 1.3;
    margin-bottom: 10px;
  }
  .btn {
    font-size: 12px;
  }
}
