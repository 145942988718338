.fullH {
  padding: 190px 40px 60px 0;
  background: url(../assets/hero.webp);
  position: relative;
  background-position: 50%;
}
.fullH h1 {
  color: #fafafa;
}

.fullH h4 {
  color: #ffffff8c;
  font-weight: 500;
  margin-bottom: 0;
  text-transform: uppercase;
}
.fullH .h1 span {
  color: #fff;
  font-weight: 700;
}
.modelo {
  margin-left: 90px;
}
.fullH h3 {
  color: #fff;
  font-weight: 400;
}

.stroke {
  -webkit-text-stroke: 1px #fafafa;
  color: transparent;
  font-weight: 700;
}

.textCol {
  width: 70%;
  min-width: 500px;
  position: relative;
}
.modelo {
  width: 40%;
}

.colorBox {
  height: 100%;
  width: 100%;
  z-index: 0;
  left: 0;
  bottom: 0;
  padding: 40px 0px 60px;
  border-radius: 5px;
  position: relative;
}

.fullw {
  width: 100%;
  display: flex;
  position: absolute;
  bottom: 0;
  height: 100px !important;
}
.modelo {
  pointer-events: none;
}

.fullH img {
  width: 100%;
  max-width: 500px;
  transform: scale(1.8);
  transform-origin: left;
  position: relative;
  top: 30%;
  left: -20%;
}

.fullH::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  background-image: linear-gradient(45deg, #002040, transparent);
  width: 100%;
  height: 100%;
}
.logo:before {
  content: "";
  background: #fff;
  position: absolute;
  width: 45%;
  height: 1px;
  right: 0;
}
.logo:after {
  content: "";
  background: #fff;
  position: absolute;
  width: 45%;
  height: 1px;
  left: 0;
}
.plan-partner {
  background-position: 0% 50%;
}

/* Media */

@media (max-width: 1200px) {
  .colorBox {
    padding: 0;
    flex-direction: column;
    margin-bottom: 0;
    margin-top: 130px;
  }
  .textCol {
    width: 100%;
    min-width: auto;
  }
}

@media (max-width: 850px) {
  .fullH {
    margin-top: 0;
    margin-bottom: 30px;
    background-position: 73% 90%;
    background-size: auto 130%;
  }
  .fullH img {
    max-width: 330px;
  }
  .fullH::before {
    background-image: linear-gradient(0deg, #002040, transparent);
  }
  .modelo {
    bottom: -55%;
  }
  .logo:before {
    width: 36%;
    margin-top: 20px;
  }
  .logo:after {
    width: 36%;
    margin-top: 20px;
  }
  .plan-partner {
    background-size: auto 110% !important;
    background-position: 56% 130%;
  }
}
