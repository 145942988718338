.header {
  top: 0;
  width: 100%;
  height: 120px;
  position: absolute;
  z-index: 9999;
  background: transparent;
  padding-bottom: 10px;
}

.header img {
  width: 150px;
  height: auto;
}
.logo {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
}
.logo span {
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 2px;
  margin-top: 10px;
  font-size: 12px;
}
@media (max-width: 850px) {
  /* Header */
  .header {
    height: 80px;
    padding: 0;
  }
  .header svg {
    width: 37px;
    height: auto;
  }
  .header img {
    width: 130px;
    padding-top: 0px;
  }
  /* /Header */
}
